<template>
  <component
    :is="tag"
    class="dropdown__wrapper">
    <slot>
      <li
        @click="$emit('select', item)"
        v-for="(item, index) in items"
        :key="`item-${index}`"
        class="dropdown__item">
        {{ getItem(item) }}
      </li>
    </slot>
  </component>
</template>

<script>

export default {
  name: 'm-Dropdown',
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    getItem: {
      type: Function,
      default: (item) => item,
    },
    tag: {
      type: String,
      default: 'ul',
      validator: (tag) => ['ol', 'ul'].includes(tag),
    },
  },
}

</script>

<style lang="scss">

.dropdown {
  &__wrapper {
    &:not(:empty) {
      z-index: 10;
      position: absolute;
      top: calc(100% + var(--space-xs));
      min-width: 0;
      width: 100%;
      max-height: 50vh;
      padding: var(--space-sm) 0;
      font-family: var(--font-stack-secondary);
      border-radius: var(--rounded-base);
      background-color: var(--color-white);
      box-shadow: 0 0 10px 5px hsla(0, 0%, 0%, .05);
      overflow-y: auto;
      overscroll-behavior-y: contain;

      @media screen and ($desktop) {
        max-height: 30vh;
      }
    }

    > * {
      &:hover {
        background-color: var(--color-grey-light);
      }
    }
  }

  &__item {
    padding: var(--space-md);
    font-size: var(--text-sm);
    font-weight: var(--font-semi);

    @include textOverflowEllipsis();
  }
}

</style>
