<template>
  <article class="google-location-finder__wrapper">
    <slot
      v-bind="{ searchText, handleSearchLocation, errors, predictions }"
      name="input">
      <FieldInput
        v-on="$listeners"
        v-bind="$attrs"
        v-model="searchText"
        @input="handleSearchLocation"
        @clear="$emit('clear')"
        :errors="errors"
        :label="label"
        :required="isRequired"
        :type="prefilledText === searchText ? 'text' : 'search'"
        id="search-text-input"
        ref="search">
      </FieldInput>
    </slot>
    <slot
      name="results"
      v-bind="{ predictions, handleSelectPrediction }">
      <Dropdown :tag="'ol'">
        <li
          v-for="(prediction, index) in predictions"
          :key="`place-prediction-${index}`"
          class="google-location-finder__predictions-item">
          <button
            @click="handleSelectPrediction(prediction)"
            type="button"
            class="google-location-finder__predictions-item-button">
            <Icon
              :variant="'location-solid'"
              :color="'neutral'"
              class="mr-xs">
            </Icon>
            <span class="google-location-finder__predictions-text google-location-finder__predictions-text--main">
              {{ prediction.mainText }}
            </span>
            <span class="google-location-finder__predictions-text google-location-finder__predictions-text--secondary">
              {{ prediction.secondaryText }}
            </span>
          </button>
        </li>
      </Dropdown>
      </slot>
  </article>
</template>

<script>

import { mapState }                   from 'vuex';

import Icon                           from '../../atoms/Icon/a-Icon.vue';
import FieldInput                     from '../../atoms/FieldInput/a-FieldInput.vue';
import Dropdown                       from '../Dropdown/m-Dropdown.vue';
import { appendScriptToDocument }     from '../../../utils/DOMUtils.js';


export default {
  name: 'm-GoogleLocationFinder',
  inheritAttrs: false,
  components: {
    Icon,
    FieldInput,
    Dropdown,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: '',
    },
    prefilledText: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchText: '',
    GMapsAutocomplete: null,
    GMapsGeocoder: null,
    predictions: [],
  }),
  beforeMount() {
    this.insertGMapsScript();
  },
  created() {
    this.initGMaps();
    this.searchText = this.prefilledText;
  },
  beforeDestroy() {
    this.GMapsAutocomplete = null;
    this.GMapsGeocoder = null;
  },
  computed: {
    ...mapState('User', ['locale']),
  },
  watch: {
    prefilledText(text) {
      if (text) this.searchText = text;
    },
  },
  methods: {
    insertGMapsScript() {
      const locale = this.locale?.locale ?? 'fr';
      const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}&libraries=places&language=${locale}`;

      appendScriptToDocument({
        src: scriptSrc,
        isAsync: false,
        isDefer: true,
        id: `gmapSDK-${locale}`
      });
    },
    initGMaps() {
      if (!window.google) return;

      this.GMapsAutocomplete = new google.maps.places.AutocompleteService(); // eslint-disable-line
      this.GMapsGeocoder = new google.maps.Geocoder(); // eslint-disable-line
    },
    handleSearchLocation(query = '') {
      if (!this.GMapsAutocomplete || !this.GMapsGeocoder) this.initGMaps();

      query
        ? this.GMapsAutocomplete.getPlacePredictions({ input: query, options: this.options }, this.handlePredictionResults)
        : this.predictions = [];
    },
    handlePredictionResults(predictions) {
      this.predictions = predictions?.map(prediction => ({
        id: prediction.place_id,
        mainText: prediction.structured_formatting.main_text,
        secondaryText: prediction.structured_formatting.secondary_text,
      })) ?? [];
    },
    handleSelectPrediction(placePrediction) {
      this.GMapsGeocoder.geocode({ placeId: placePrediction.id }, this.handlePredictionDetailsResult);
    },
    handlePredictionDetailsResult([ placeResult ]) {
      if (!placeResult) return;

      this.$emit('place-selected', placeResult);
      this.searchText = placeResult.formatted_address;
      this.predictions = [];
    },
  }
}

</script>

<style lang="scss">

.google-location-finder {
  &__wrapper {
    position: relative;
    flex: 1;
    min-width: 0;
    font-family: var(--font-stack-secondary);
  }

  &__predictions-item {
    display: flex;

    &:last-child {
      border-bottom: none;
    }
  }

  &__predictions-item-button {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    padding: var(--space-sm);
  }

  &__predictions-text {
    margin-right: var(--space-xs);

    &--main {
      font-weight: var(--font-bold);
      font-size: var(--text-sm);
      white-space: nowrap;
    }

    &--secondary {
      flex: 1 0 0;
      color: var(--color-grey-dark);
      font-size: var(--text-xs);
      text-align: right;

      @include textOverflowEllipsis();
    }
  }
}

</style>